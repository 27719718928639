// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-appointment-js": () => import("./../../../src/pages/appointment.js" /* webpackChunkName: "component---src-pages-appointment-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-customized-wigs-for-men-js": () => import("./../../../src/pages/customized-wigs-for-men.js" /* webpackChunkName: "component---src-pages-customized-wigs-for-men-js" */),
  "component---src-pages-customized-wigs-for-women-js": () => import("./../../../src/pages/customized-wigs-for-women.js" /* webpackChunkName: "component---src-pages-customized-wigs-for-women-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-feedback-js": () => import("./../../../src/pages/feedback.js" /* webpackChunkName: "component---src-pages-feedback-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-hair-bonding-for-men-js": () => import("./../../../src/pages/hair-bonding-for-men.js" /* webpackChunkName: "component---src-pages-hair-bonding-for-men-js" */),
  "component---src-pages-hair-bonding-for-women-js": () => import("./../../../src/pages/hair-bonding-for-women.js" /* webpackChunkName: "component---src-pages-hair-bonding-for-women-js" */),
  "component---src-pages-hair-extensions-for-men-js": () => import("./../../../src/pages/hair-extensions-for-men.js" /* webpackChunkName: "component---src-pages-hair-extensions-for-men-js" */),
  "component---src-pages-hair-extention-for-womens-js": () => import("./../../../src/pages/hair-extention-for-womens.js" /* webpackChunkName: "component---src-pages-hair-extention-for-womens-js" */),
  "component---src-pages-hair-fixing-js": () => import("./../../../src/pages/hair-fixing.js" /* webpackChunkName: "component---src-pages-hair-fixing-js" */),
  "component---src-pages-hair-patch-mens-js": () => import("./../../../src/pages/hair-patch-mens.js" /* webpackChunkName: "component---src-pages-hair-patch-mens-js" */),
  "component---src-pages-hair-replacement-for-men-js": () => import("./../../../src/pages/hair-replacement-for-men.js" /* webpackChunkName: "component---src-pages-hair-replacement-for-men-js" */),
  "component---src-pages-hair-replacement-for-women-js": () => import("./../../../src/pages/hair-replacement-for-women.js" /* webpackChunkName: "component---src-pages-hair-replacement-for-women-js" */),
  "component---src-pages-hair-weaving-for-mens-js": () => import("./../../../src/pages/hair-weaving-for-mens.js" /* webpackChunkName: "component---src-pages-hair-weaving-for-mens-js" */),
  "component---src-pages-hair-weaving-for-women-js": () => import("./../../../src/pages/hair-weaving-for-women.js" /* webpackChunkName: "component---src-pages-hair-weaving-for-women-js" */),
  "component---src-pages-hair-wigs-for-men-in-cp-park-js": () => import("./../../../src/pages/hair-wigs-for-men-in-cp-park.js" /* webpackChunkName: "component---src-pages-hair-wigs-for-men-in-cp-park-js" */),
  "component---src-pages-hair-wigs-for-men-in-dwarka-js": () => import("./../../../src/pages/hair-wigs-for-men-in-dwarka.js" /* webpackChunkName: "component---src-pages-hair-wigs-for-men-in-dwarka-js" */),
  "component---src-pages-hair-wigs-in-delhi-js": () => import("./../../../src/pages/hair-wigs-in-delhi.js" /* webpackChunkName: "component---src-pages-hair-wigs-in-delhi-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-non-surgical-hair-replacement-for-men-js": () => import("./../../../src/pages/non-surgical-hair-replacement-for-men.js" /* webpackChunkName: "component---src-pages-non-surgical-hair-replacement-for-men-js" */),
  "component---src-pages-non-surgical-hair-replacement-js": () => import("./../../../src/pages/non-surgical-hair-replacement.js" /* webpackChunkName: "component---src-pages-non-surgical-hair-replacement-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-t-c-radiance-js": () => import("./../../../src/pages/t&cRadiance.js" /* webpackChunkName: "component---src-pages-t-c-radiance-js" */),
  "component---src-pages-take-the-hair-test-js": () => import("./../../../src/pages/take-the-hair-test.js" /* webpackChunkName: "component---src-pages-take-the-hair-test-js" */),
  "component---src-pages-tape-in-and-clip-in-hair-extension-2-js": () => import("./../../../src/pages/tape-in-and-clip-in-hair-extension2.js" /* webpackChunkName: "component---src-pages-tape-in-and-clip-in-hair-extension-2-js" */),
  "component---src-pages-tape-in-and-clip-in-hair-extension-js": () => import("./../../../src/pages/tape-in-and-clip-in-hair-extension.js" /* webpackChunkName: "component---src-pages-tape-in-and-clip-in-hair-extension-js" */),
  "component---src-pages-tape-in-and-clip-in-womens-js": () => import("./../../../src/pages/tape-in-and-clip-in-womens.js" /* webpackChunkName: "component---src-pages-tape-in-and-clip-in-womens-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-volumizer-hair-additions-for-women-js": () => import("./../../../src/pages/volumizer-hair-additions-for-women.js" /* webpackChunkName: "component---src-pages-volumizer-hair-additions-for-women-js" */),
  "component---src-pages-wigs-for-men-cancer-patients-js": () => import("./../../../src/pages/wigs-for-men-cancer-patients.js" /* webpackChunkName: "component---src-pages-wigs-for-men-cancer-patients-js" */),
  "component---src-pages-wigs-for-men-js": () => import("./../../../src/pages/wigs-for-men.js" /* webpackChunkName: "component---src-pages-wigs-for-men-js" */),
  "component---src-pages-wigs-for-womens-js": () => import("./../../../src/pages/wigs-for-womens.js" /* webpackChunkName: "component---src-pages-wigs-for-womens-js" */),
  "component---src-pages-womens-wigs-for-cancer-patients-js": () => import("./../../../src/pages/womens-wigs-for-cancer-patients.js" /* webpackChunkName: "component---src-pages-womens-wigs-for-cancer-patients-js" */)
}

